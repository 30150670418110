<template>
  <div class="content-wrapper" :class="isStop.length !== 0 ? 'content-wrapper--max-size' : ''">
    <slot />
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ContentWrapper",

  computed: {
    ...mapGetters(['isStop']),
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  width: 100%;
  //height: 1152px;
  height: 1288px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }

  &--max-size {
    height: calc(1553px - 304px);
  }
}
</style>
